// Webpack Imports
import * as bootstrap from "bootstrap";
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

(function() {
  "use strict";

  // Focus input if Searchform is empty
  [].forEach.call(document.querySelectorAll(".search-form"), (el) => {
    el.addEventListener("submit", function(e) {
      var search = el.querySelector("input");
      if (search.value.length < 1) {
        e.preventDefault();
        search.focus();
      }
    });
  });

  // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]'),
  );
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, {
      trigger: "focus",
    });
  });

  (function toggleNavSearchOpen() {
    var openSearchFormBtns = document.querySelectorAll(
      ".open-nav-search-form-btn",
    );
    var closeSearchFormBtns = document.querySelectorAll(
      ".close-nav-search-form-btn",
    );
    var elsHiddenOnSearchActive = document.querySelectorAll(
      ".hide-on-search-active",
    );
    var elsVisibleOnSearchActive = document.querySelectorAll(
      ".show-on-search-active",
    );

    var searchForms = document.querySelectorAll(".nav-search-form");

    var hiddenClass = "visually-hidden";

    if (
      !openSearchFormBtns.length ||
      !closeSearchFormBtns.length ||
      !searchForms.length
    ) {
      console.debug("no search form nor toggle");
      return;
    }

    openSearchFormBtns.forEach(function(btn) {
      btn.addEventListener("click", function() {
        searchForms.forEach(function(form) {
          if (form.classList.contains(hiddenClass)) {
            form.classList.remove(hiddenClass);
          }
        });

        if (btn.classList.contains("hide-on-toggle")) {
          btn.classList.add(hiddenClass);
        }

        elsHiddenOnSearchActive.forEach(function(el) {
          if (!el.classList.contains(hiddenClass)) {
            el.classList.add(hiddenClass);
          }
        });
        elsVisibleOnSearchActive.forEach(function(el) {
          if (el.classList.contains(hiddenClass)) {
            el.classList.remove(hiddenClass);
          }
        });
      });
    });

    closeSearchFormBtns.forEach(function(btn) {
      btn.addEventListener("click", function() {
        searchForms.forEach(function(form) {
          if (!form.classList.contains(hiddenClass)) {
            form.classList.add(hiddenClass);
          }
        });

        if (btn.classList.contains("hide-on-toggle")) {
          btn.classList.add(hiddenClass);
        }

        openSearchFormBtns.forEach(function(openBtn) {
          if (openBtn.classList.contains(hiddenClass)) {
            openBtn.classList.remove(hiddenClass);
          }
        });

        elsHiddenOnSearchActive.forEach(function(el) {
          if (el.classList.contains(hiddenClass)) {
            el.classList.remove(hiddenClass);
          }
        });
      });
    });
  })();

  (function anchorHiglighting() {
    var elsNeedingHighlighted = document.querySelectorAll(
      ".highlight-if-anchored",
    );

    var highlightClass = "highlighted";

    if (!elsNeedingHighlighted.length) {
      console.debug("no highlightable items");
      return;
    }

    elsNeedingHighlighted.forEach(function(el) {
      el.addEventListener("click", function() {
        if (!el.classList.contains(highlightClass)) {
          el.classList.add(highlightClass);
          unhighlightAllExcept(el);
        }
      });
    });

    /**
     * @param {Element} el
     */
    function unhighlightAllExcept(el) {
      elsNeedingHighlighted.forEach(function(removeHighlightEl) {
        if (
          !removeHighlightEl.isSameNode(el) &&
          removeHighlightEl.classList.contains(highlightClass)
        ) {
          removeHighlightEl.classList.remove(highlightClass);
        }
      });
    }
  })();

  (function addCurrentPageActiveClass() {
    var elsToCheck = document.querySelectorAll(".set-active-if-is-page-slug");

    if (!elsToCheck.length) {
      console.debug("no page-slug activatable items");
      return;
    }

    var currPageSlug = getCurrentPageSlug();

    elsToCheck.forEach(function(el) {
      var elPageSlug = null;

      el.classList.forEach(function(c) {
        if (c.includes("set-page-")) {
          elPageSlug = c.slice(0, 9);
        }
      });

      console.log({ elPageSlug, currPageSlug });

      if (!el.classList.contains("active")) {
        el.classList.add("active");
      }
    });
  })();

  (function paginatePosts() {
    var paginationSections = document.querySelectorAll("[data-xur-paginate]");

    if (!paginationSections.length) {
      console.debug("no paginatable items");
    }

    paginationSections.forEach(function(pagiSection) {
      var pages = pagiSection.querySelectorAll("[data-xur-paginate-page]");
      var prevBtn = pagiSection.querySelector(
        '[data-xur-paginate-prev="true"]',
      );
      var nextBtn = pagiSection.querySelector(
        '[data-xur-paginate-next="true"]',
      );
      var pageToBtns = pagiSection.querySelectorAll("[data-xur-paginate-to]");

      pageToBtns.forEach(function(btn) {
        btn.addEventListener("click", function() {
          var selectedPagePos = btn.getAttribute("data-xur-paginate-to");
          selectPage(selectedPagePos);
        });
      });

      if (prevBtn)
        prevBtn.addEventListener("click", function() {
          var selectedPagePos = pagiSection
            .querySelector('[data-xur-paginate-page-selected="true"]')
            .getAttribute("data-xur-paginate-page");

          if (selectedPagePos == 1) {
            selectedPagePos = pages.length;
          } else {
            selectedPagePos = Number(selectedPagePos) - 1;
          }

          selectPage(selectedPagePos);
        });

      if (nextBtn)
        nextBtn.addEventListener("click", function() {
          var selectedPagePos = pagiSection
            .querySelector('[data-xur-paginate-page-selected="true"]')
            .getAttribute("data-xur-paginate-page");

          if (selectedPagePos == pages.length) {
            selectedPagePos = 1;
          } else {
            selectedPagePos = Number(selectedPagePos) + 1;
          }

          selectPage(selectedPagePos);
        });

      function selectPage(pos) {
        pages.forEach(function(page) {
          if (!page.classList.contains("visually-hidden")) {
            page.classList.add("visually-hidden");
            page.setAttribute("data-xur-paginate-page-selected", false);
          }
          if (page.getAttribute("data-xur-paginate-page") == pos) {
            page.classList.remove("visually-hidden");
            page.setAttribute("data-xur-paginate-page-selected", true);
          }
        });

        pageToBtns.forEach(function(btn) {
          btn.setAttribute("data-xur-paginate-selected", false);
          if (btn.getAttribute("data-xur-paginate-to") == pos) {
            btn.setAttribute("data-xur-paginate-selected", true);
          }
        });
      }
    });
  })();

  (function addBodyClassWhenNotAtTop() {
    var body = document.querySelector("body");
    document.addEventListener("scroll", function() {
      if (window.scrollY > 0 && !body.classList.contains("not-at-top")) {
        body.classList.add("not-at-top");
      } else if (window.scrollY <= 0 && body.classList.contains("not-at-top")) {
        body.classList.remove("not-at-top");
      }
    });
  })();

  (function addImageToImageExpandModal() {
    var modal = document.querySelector("#imageExpandModal");
    var imageContainers = document.querySelectorAll(".image-expand-container");

    if (!imageContainers.length) {
      console.debug("no images to expand");
    }

    imageContainers.forEach(function(container) {
      var btn = container.querySelector(".image-expand-btn");
      var image = container.querySelector(".image-expand");

      btn.addEventListener("click", function() {
        var prevImg = modal.querySelector("img");
        if (prevImg) prevImg.remove();
        var modalImg = document.createElement("img");
        modalImg.setAttribute("src", image.getAttribute("src"));
        modalImg.setAttribute("alt", image.getAttribute("alt"));
        modalImg.setAttribute("width", "100%");
        modalImg.setAttribute("height", "auto");

        modal.querySelector(".modal-content").appendChild(modalImg);
      });
    });
  })();

  (function addAuthorDetailsToAuthorModal() {
    var modal = document.querySelector("#authorBioModal");
    var targetAuthorBioWrappers = document.querySelectorAll(".target-author-bio-wrapper");

    if (!targetAuthorBioWrappers.length) {
      console.debug("no images to expand");
    }

    targetAuthorBioWrappers.forEach(function(container) {
      var bio = container.querySelector('.bio');
      container.addEventListener('click', function() {
        var prevContent = modal.querySelector(".content");
        if (prevContent) prevContent.remove();

        var wrapper = document.createElement('div');
        wrapper.classList.add('content', 'row');

        var firstCol = document.createElement('div');
        firstCol.classList.add('col-lg-4');
        var secondCol = document.createElement('div');
        secondCol.classList.add('col-lg-8', 'p-4');

        var containerDup = container.cloneNode(true);
        containerDup.classList.add('border-0', 'pt-5');
        firstCol.appendChild(containerDup);

        if (bio) {
          var dup = bio.cloneNode(true);
          dup.classList.remove('visually-hidden-focusable');
          secondCol.appendChild(dup);
        }

        wrapper.appendChild(firstCol);
        wrapper.appendChild(secondCol)

        modal.querySelector('.modal-content').appendChild(wrapper);
      });
    });
  })();


  (function addMultiItemBootstrapCarouselSupport() {
    let items = document.querySelectorAll(
      ".carousel.carousel-multi-item .carousel-item",
    );

    items.forEach((el) => {
      // number of slides per carousel-item
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  })();

  (function hideMobileNavElemsOnCollapse() {
    var navbar = document.getElementById('navbar');
    var toggleButton = document.querySelector('.navbar-toggler');
    var elementsToHideOnShow = document.querySelectorAll('.hide-on-mobile-nav-collapse-show');

    if (!navbar || !elementsToHideOnShow.length || ! toggleButton) {
      console.debug('no elements to hide or show for hideMobileNavElemsOnCollapse');
      return;
    }

    if (elementsToHideOnShow.length) {
      navbar.addEventListener('show.bs.collapse', function(event) {
        if (event.target === navbar) {
          elementsToHideOnShow.forEach(function(el) {
            el.classList.add('visually-hidden');
          });
        }
      });
      navbar.addEventListener('hide.bs.collapse', function(event) {
        if (event.target === navbar) {
          elementsToHideOnShow.forEach(function(el) {
            el.classList.remove('visually-hidden');
          });
        }
      });
    }
  })();

  (function addUnderlayOnNavbarToggleOpen() {
    var navbar = document.getElementById('navbar');
    var toggleButton = document.querySelector('.navbar-toggler');

    if (!navbar || !toggleButton) {
      console.debug('no elements to hide or show for addUnderlayOnNavbarToggleOpen');
      return;
    }

    navbar.addEventListener('show.bs.collapse', function(event) {
      if (event.target === navbar) {
        document.body.classList.add('nav-underlay--active');
      }
    });
    navbar.addEventListener('hide.bs.collapse', function(event) {
      if (event.target === navbar) {
        document.body.classList.remove('nav-underlay--active');
      }
    });
  })();


  (function hoverActions() {
    if (isMobile()) return;

    var containers = document.querySelectorAll('[data-xurr-hover-actions]');
    var watchers = document.querySelectorAll('[data-xurr-hover-actions-watcher]');

    if (!containers.length) {
      console.debug('no elements with [data-xurr-hover-actions]');
      return;
    }

    containers.forEach(function(container) {
      var slide = container.querySelector('[data-xurr-hover-actions-slide]');

      if (!slide) {
        console.debug('no element with [data-xurr-hover-actions-slide]');
        return;
      }
      var height = outerHeight(slide);
      var timeout = null;

      slide.style.maxHeight = '0px';
      container.addEventListener('mouseenter', function(_event) {
        container.style.position = 'absolute';
        container.style.zIndex = 1;
        if (timeout) clearTimeout(timeout);
        slide.style.maxHeight = height + 'px';
      });
      container.addEventListener('mouseleave', function(_event) {
        slide.style.maxHeight = '0px';
        container.style.zIndex = 0;
        timeout = setTimeout(function() {
          container.style.position = 'relative';
        }, 1000);
      });
    });

    // run watchers after in order to handle for content shift
    watchers.forEach(function(watcher) {
      var hoverActions = watcher.querySelectorAll('[data-xurr-hover-actions]');

      if (!hoverActions.length) {
        console.debug('no elements with [data-xurr-hover-actions]');
        return;
      }

      var hoverActionsHeight = 0;

      hoverActions.forEach(function(hv) {
        var hvHeight = outerHeight(hv);
        if (hoverActionsHeight < hvHeight) {
          hoverActionsHeight = hvHeight;
        }
      });

      watcher.style.height = hoverActionsHeight + 'px';
    });
  })();

  (function cookieSettingsToggle() {
    var cookieToggles = document.querySelectorAll('[title="cookie-settings-toggle"]');


    if (!cookieToggles.length) {
      console.debug('no elements with [title="cookie-settings-toggle"]');
      return;
    }

    cookieToggles.forEach(function(toggle) {
      toggle.style.cursor = 'pointer';
      toggle.addEventListener('click', function() {
        if (typeof Cookiebot !== 'undefined') {
          Cookiebot.renew();
        } else {
          console.debug('no global class `Cookiebot` found');
        }
      });
    });
  })();


  // Carousels

  const swiper = new Swiper('.swiper', {
    speed: 400,
    spaceBetween: 100,
  });

  const swiperCarouselAwardsBlock = new Swiper('.swiper-carousel-awards-block', {
    modules: [Autoplay],
    speed: 800,
    spaceBetween: 10,
    autoplay: {
      delay: 4000,
    },
    slidesPerView: 1,
    breakpoints: {
      640: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      }
    }
  })

  function getCurrentPageSlug() {
    var pathname = window.location.pathname;
    var paths = pathname.match(/[^\/]+/g);
    if (!paths || !paths.length) return null;
    return paths[paths.length - 1];
  }

  function outerHeight(el) {
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
                 parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
  }


  function isMobile() {
    return window.innerWidth < 680;
  }

})();
